import React from 'react'
import { Link } from 'gatsby'
import { Box } from 'grommet'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProductNav from '../../components/ProductNav'
import FormContactsList from '../../components/FormContactsList'

const PostcardsList = () => (
  <Layout productNav="postcards">
    <SEO title="Postcards - Add Your Mailing List" />
    <Box pad={{ vertical: 'medium' }} fill>
      <FormContactsList product={'postcards'} />
    </Box>
  </Layout>
)

export default PostcardsList
